import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Row, Shim, PageTitle } from "../components/row"
import styled, { css } from "styled-components"
import CSS from "../config/css"
import PhotoGrid from "../components/photo-grid"
import SEO from "../components/seo"

import imageAbout from "../images/about/megan-and-beverly.jpg"
import badgeGrace from "../images/about/graceormond-badge.png"
import badgeWeddingWire from "../images/about/weddingwire-badge.png"
import badgeTheKnot from "../images/about/theknot-badge.png"
import badgeGuru from "../images/about/guru-badge.png"

const pageTitle = "About"
const ImagesQuery = graphql`
  query {
    about: allFile(filter: { relativePath: { regex: "/megan-and-beverly/" } }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`

const BadgeRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media ${CSS.mq.medium} {
    flex-direction: row;
  }
`

const Badge = styled.img`
  flex: 1;
  max-width: 200px;
  height: auto;
  padding: 10px;
`

export default () => (
  <Layout>
    <SEO title={pageTitle} />
    <Row>
      <PageTitle>{pageTitle}</PageTitle>
      <StaticQuery
        query={ImagesQuery}
        render={data => {
          return (
            <>
              <PhotoGrid name="about" images={data.about.edges} />
            </>
          )
        }}
      />
      <Shim>
        <p>
          It all began with my beautifully ambitious mother, Jimmie. As a
          working mother, she developed a passion for baking pretty confections
          for her loved ones. She began making cakes for friends, family and
          co-workers and the treats were loved by all. Mom loved bringing people
          joy and drawing them together with her creations. This continued for
          years until Mom's love of baking inspired her and my father to open
          their own bakery in Fresno, California. Jimmie's Bakery was one of the
          best places in town to get wedding cakes, birthday cakes, cookies,
          pastries and the like. It was a small, family-run business where I
          learned at a young age to decorate wedding cakes. I continued to work
          alongside my mom as a young mother of 4 children. My kids grew up
          immersed in the bakery life; folding boxes, helping customers and
          learning the small business trade.
        </p>

        <p>
          When my parents retired in 2008, I quickly realized there was a great
          demand for wedding cakes and other special occasion cakes. Where were
          people going to get their cakes?! Along with my daughter and business
          partner Megan, as well as family and friends, we decided to open
          Frosted Cakery. Our business plan is to make "Dreams in Buttercream"
          for all! We believe with our love and artistry, we can change the
          world, one Frosted treat at a time.
        </p>
        <BadgeRow>
          <Badge src={badgeWeddingWire} alt="Wedding Wire Rated" />
          <Badge src={badgeTheKnot} alt="As Seen on The Knot" />
          <Badge src={badgeGrace} alt="As Seen on Grace Ormonde" />
          <Badge src={badgeGuru} alt="As Seen on Grace Ormonde" />
        </BadgeRow>
      </Shim>
    </Row>
  </Layout>
)
